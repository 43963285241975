
.cs-card.cs-style1 {
  position: relative;
  border-radius: 15px;
  display: block;
  transition: all 0.5s ease-out; /* added transition for smooth animation */

  &:hover {
    transform: scale(1.25); /* increased size on hover */
    filter: drop-shadow(0px 0px 30px rgba(255, 74, 23, 0.5));
    .cs-card_info {
      padding: 15px 20px 0;
      h2 {
        font-size: 13px;
        color: #5d91cf;
      }
      p {
        max-height: 140px;
        overflow: hidden;
        color: white;
      }
    }
  }

  img {
    width: 100%;
    border-radius: inherit;
  }

  .cs-card_overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    background: #000000;
    opacity: 0.5;
  }

  .cs-card_info {
    position: absolute;
    padding: 60px 25px 0;
    top: 0;
    left: 0;

    span {
      display: block;
      height: 12px;
      width: 12px;
      border-radius: 50%;
      margin-bottom: 10px;
    }

    h2 {
      font-size: 20px;
      margin: 0;
      line-height: 1.5em;
    }

    p {
      font-size: 12px;
      margin: 0;
      line-height: 1.5em;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.5s ease-out;
    }
  }
}

@media screen and (max-width: 1400px) {
  .cs-card.cs-style1 .cs-card_title {
    font-size: 20px;
  }
  .cs-card.cs-style1 .cs-card_info {
    padding: 40px 18px 0;
  }
}

@media screen and (max-width: 1199px) {
  .cs-card.cs-style1 .cs-card_title {
    font-size: 24px;
  }
  .cs-card.cs-style1 .cs-card_info {
    padding: 40px 30px;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }
}






