/*--------------------------------------------------------------
## Basic Color
----------------------------------------------------------------*/
$white: #fff;
$black: #161616;
$primary: #FEFEFE;
$secondary: rgba($primary, 0.7);
$ternary: #999696;
$border: #4D4D4D;
$gray: rgb(2,0,36);
$accent: #476f95;
//#230edfd5;
// $accent: #FF4A17;
