// .cs-partner_logo_wrap {
//   display: flex;
//   justify-content: space-between;
//   margin: 0 -15px;
// }
// .cs-partner_logo {
//   padding: 15px;
// }
// @media screen and (max-width: 575px) {
//   .cs-partner_logo_wrap {
//     margin: 0 -10px;
//   }
// }

// .cs-logo_container {
//   display: flex;
//   justify-content: center;
// }

// .cs-logo_section {
//   flex: 1;
// }

// .cs-logo_separator {
//   border-right: 1px solid #ccc;
//   margin: 0 10px;
// }

// .cs-logo_list {
//   display: flex;
//   flex-wrap: wrap;
//   flex-direction: center;
//   align-items: center;
//   gap: 10px;
// }

// .cs-logo_row {
//   display: flex;
//   flex-basis: 100%;
//   align-items: center;
// }

// .cs-logo_col {
//   flex: 1;
//   text-align: center;
//   margin-bottom: 10px;
//   padding: 20px;
// }

// .cs-logo_col img {
//   max-width: 100%;
//   height: auto;
// }


// h2 {
//   text-align: center;
// }

// @media screen and (max-width: 768px) {
//   .cs-logo_list {
//     flex-direction: column;
//   }
// }

/* Desktop */
.cs-logo_container {
  display: flex;
  justify-content: center;
}

.cs-logo_section {
  flex: 1;
}

.cs-logo_separator {
  border-right: 1px solid #ccc;
  margin: 0 10px;
}

.cs-logo_list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: center;
  align-items: center;
  // gap: 10px;
}

.cs-logo_row {
  display: flex;
  flex-basis: 100%;
  align-items: center;
}

.cs-logo_col {
  flex: 1;
  text-align: center;
  margin-bottom: 10px;
  padding-left: 2px;
  padding-right: 2px;
}

.cs-logo_col img {
  max-width: 100%;
  height: auto;
}

h2 {
  text-align: center;
}

// /* Mobile */
// @media screen and (max-width: 768px) {
//   .cs-logo_container {
//     display: block;
//   }

//   .cs-logo_section {
//     margin-bottom: 20px;
//   }

//   .cs-logo_separator {
//     display: none;
//   }

//   .cs-logo_list {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     gap: 20px;
//   }

//   .cs-logo_row {
//     flex-basis: auto;
//     width: 100%;
//   }

//   .cs-logo_col {
//     flex: none;
//     margin-bottom: 0;
//     padding: 10px;
//   }
// }
