// .cs-card2.cs-style1 {
//   position: relative;
//   border-radius: 15px;
//   display: block;
//   transition: all 0.5s ease-out; /* added transition for smooth animation */

//   &:hover {
//     // transform: scale(1.25); /* increased size on hover */
//     filter: drop-shadow(0px 0px 30px rgba(23, 255, 216, 0.5));
//     .cs-card2_info {
//       padding: 15px 20px 0;
//       h2 {
//         font-size: 13px;
//         color: #5d91cf;
//       }
//       p {
//         max-height: 140px;
//         overflow: hidden;
//         color: white;
//       }
//     }
//   }

//   img {
//     width: 100%;
//     border-radius: inherit;
//   }

//   .cs-card2_overlay {
//     position: absolute;
//     left: 0;
//     top: 0;
//     width: 100%;
//     height: 100%;
//     border-radius: inherit;
//     background: #000000;
//     opacity: 0.5;
//   }

//   .cs-card2_info {
//     position: absolute;
//     padding: 60px 25px 0;
//     top: 0;
//     left: 0;

//     span {
//       display: block;
//       height: 12px;
//       width: 12px;
//       border-radius: 50%;
//       margin-bottom: 10px;
//     }

//     h2 {
//       font-size: 20px;
//       margin: 0;
//       line-height: 1.5em;
//     }

//     p {
//       font-size: 12px;
//       margin: 0;
//       line-height: 1.5em;
//       max-height: 0;
//       overflow: hidden;
//       transition: max-height 0.5s ease-out;
//     }
//   }
// }

// @media screen and (max-width: 1400px) {
//   .cs-card2.cs-style1 .cs-card2_title {
//     font-size: 20px;
//   }
//   .cs-card2.cs-style1 .cs-card2_info {
//     padding: 40px 18px 0;
//   }
// }

// @media screen and (max-width: 1199px) {
//   .cs-card2.cs-style1 .cs-card2_title {
//     font-size: 24px;
//   }
//   .cs-card2.cs-style1 .cs-card2_info {
//     padding: 40px 30px;
//     height: 100%;
//     display: flex;
//     justify-content: center;
//     flex-direction: column;
//     width: 100%;
//   }
// }


.cs-card2.cs-style1 {
  position: relative;
  border-radius: 15px;
  display: block;
  transition: all 0.5s ease-out;


  &:hover {
    transform: scale(1.04);
    /* increased size on hover */
    filter: drop-shadow(0px 0px 30px rgba(23, 255, 216, 0.5));

    .cs-card2_info h2 {
      color: #5d91cf;
    }

  }

  img {
    width: 100%;
    border-radius: inherit;
  }

  .cs-card2_overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    background: rgba(18, 18, 18, 0.45);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8.9px);
    -webkit-backdrop-filter: blur(13.9px);
  }

  .cs-card2_info {
    position: absolute;
    padding: 30px 25px 0;
    height: 100%;
    display: flex;
    flex-direction: column ;
    top: 0;
    left: 0;
  /* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #1717C6 #A9B1B3;
}

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
  width: 5px;
  width: 5px;
}
*::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #A9B1B3;
}

*::-webkit-scrollbar-track:hover {
  background-color: #B8C0C2;
}

*::-webkit-scrollbar-track:active {
  background-color: #B8C0C2;
}

*::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #5c5cdb;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #323DA6;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #4232cab6;
}


    span {
      display: block;
      height: 12px;
      width: 12px;
      border-radius: 50%;
      margin-bottom: 10px;
    }

    h2 {
      font-size: 1.9rem;
      margin: 2px;
      line-height: 1.5em;
    }


    p {
      font-size: 1.12rem;
      margin: 4px;
      line-height: 1.5em;
      color: white;
      overflow: overlay;
      padding-right: 3px;
    }
  }

  @media screen and (max-width: 1400px) {
    .cs-card2.cs-style1 .cs-card2_title {
      font-size: 20px;
    }
  
    .cs-card2.cs-style1 .cs-card2_info {
      padding: 25px 20px;
      height: 100%;
      display: flex;
      flex-direction: column;
      width: 100%;
  
  
      h2 {
        font-size: 1.5rem;
        margin: 1px;
        line-height: 1.5em;
      }
  
  
      p {
        font-size: 1rem;
        margin: 2px;
        line-height: 1.5em;
        color: white;
      }
    }
  }}


// @media screen and (max-width: 1400px) {
//   .cs-card2.cs-style1 .cs-card2_title {
//     font-size: 20px;
//   }

//   .cs-card2.cs-style1 .cs-card2_info {
//     padding: 40px 18px 0;
//   }
// }

@media screen and (max-width: 1199px) {
  .cs-card2.cs-style1 .cs-card2_title {
    font-size: 20px;
  }

  .cs-card2.cs-style1 .cs-card2_info {
    padding: 25px 20px;
    height: 100%;
    display: flex;
    // justify-content: center;
    flex-direction: column;
    width: 100%;


    h2 {
      font-size: 1.5rem;
      margin: 1px;
      line-height: 1.5em;
      // border-bottom: 1px solid #ffffff;
      // font-weight: 5;
    }


    p {
      font-size: 1rem;
      margin: 2px;
      line-height: 1.5em;
      color: white;
    }
  }
}





// .cs-card2.cs-style1 {
//   position: relative;
//   border-radius: 15px;
//   display: block;
//   transition: all 0.5s ease-out;

//   &:hover {
//     filter: drop-shadow(0px 0px 30px rgba(23, 255, 216, 0.5));
//   }

//   .cs-card2_overlay {
//     position: absolute;
//     left: 0;
//     top: 0;
//     width: 100%;
//     height: 100%;
//     border-radius: inherit;
//     background: rgba(44, 41, 41, 0.23);
//     border-radius: 16px;
//     box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
//     backdrop-filter: blur(11.4px);
//     -webkit-backdrop-filter: blur(11.4px);
//     opacity: 0.5;
//   }

//   .cs-card2_info {
//     position: absolute;
//     padding: 60px 25px 0;
//     top: 0;
//     left: 0;

//     span {
//       display: block;
//       height: 12px;
//       width: 12px;
//       border-radius: 50%;
//       margin-bottom: 10px;
//     }

//     h2 {
//       font-size: 20px;
//       margin: 0;
//       line-height: 1.5em;
//     }

//     p {
//       font-size: 12px;
//       margin: 0;
//       line-height: 1.5em;
//       color: white;
//     }
//   }
// }

// @media screen and (max-width: 1400px) {
//   .cs-card2.cs-style1 .cs-card2_title {
//     font-size: 20px;
//   }

//   .cs-card2.cs-style1 .cs-card2_info {
//     padding: 40px 18px 0;
//   }
// }

// @media screen and (max-width: 1199px) {
//   .cs-card2.cs-style1 .cs-card2_title {
//     font-size: 24px;
//   }

//   .cs-card2.cs-style1 .cs-card2_info {
//     padding: 40px 30px;
//     height: 100%;
//     display: flex;
//     justify-content: center;
//     flex-direction: column;
//     width: 100%;
//   }
// }